import React, { Component } from 'react';
import { Link } from 'gatsby';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
    
  }
  toggleMenu = value => {
    this.setState({ openMenu: value });
  };

  handleClick(event) {
    event.preventDefault();
    const navContainer = document.getElementById('globalNav');
    if (navContainer.classList.contains('is-open')) {
      navContainer.classList.remove('is-open')
    } else {
      navContainer.classList.add('is-open')
    }
  }

  render() {
    const { openMenu } = this.state;
    const { activeLink } = this.props;
    return (
        <header className="header clearfix" role="banner">
            <nav id="globalNav" className={`navigation clearfix ${openMenu ? ' is-open' : ''}`} >
                <a href="/" title="Return to the homepage" className="navigation__logo">
                    <img src="https://res.cloudinary.com/dfvhyk2ut/image/upload/v1574605736/lindan-moments/logo.jpg" alt="Lindan Classic Moments"/>
                </a>

                <button id="globalNavToggle" 
                 onClick={_ => this.toggleMenu(!openMenu)}
                 className="button button--hollow navigation__button navbar-toggler"
                 aria-controls="navbarResponsive" 
                 aria-expanded={openMenu}
                 aria-label="Toggle navigation">
                    Menu
                </button>

                <ul className="navigation__list">
                    <li className="navigation__item">
                      <Link className={`navigation__item-link${ activeLink === 'home' ? ' navigation__item-link--active': ''}`} to="/">Home</Link>
                    </li>
                    <li className="navigation__item">
                        <Link className={`navigation__item-link${ activeLink === 'what-we-do' ? ' navigation__item-link--active': ''}`} to="/what-we-do">What we do</Link>
                    </li>
                    <li className="navigation__item navigation__item--promoted">
                        <Link className={`navigation__item-link${ activeLink === 'contact' ? ' navigation__item-link--active': ' navigation__item-link--promoted'}`} to="/contact">Contact us</Link>
                    </li>
                </ul>
            </nav>
        </header>
        );
}
}