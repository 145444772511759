import React, { Component } from 'react';
import { Link } from 'gatsby';

import config from '../../config';

export default class Footer extends Component {
  
  render() {
    return (
      <div className="wrapper">
          <div className="container">
              <div className="row">
                  <div className="col-sm-24">
                      <footer className="footer">
                          <div className="text-center">
                              <div className="footer__social">
                                  <a className="footer__social-link" href="https://www.facebook.com/pages/category/Product-Service/Lindan-Classic-Moments-181417112583915/" target="_blank"  rel="noreferrer">
                                      <span className="visually-hidden">Find us on Facebook</span>
                                      <img className="footer__social-icon" src="https://res.cloudinary.com/dfvhyk2ut/image/upload/f_auto,q_auto,w_160,h_160/v1592250783/lindan-moments/icons/icon-facebook.svg" alt="Facebook" aria-hidden="true"/>
                                  </a>
      
                                  <a className="footer__social-link" href="https://www.instagram.com/lindanclassicmoments/" target="_blank"  rel="noreferrer">
                                      <span className="visually-hidden">Visit our Instagram account: @lindanclassicmoments</span>
                                      <img className="footer__social-icon" src="https://res.cloudinary.com/dfvhyk2ut/image/upload/f_auto,q_auto,w_160,h_160/v1592250783/lindan-moments/icons/icon-instagram.svg" alt="Instagram" aria-hidden="true"/>
                                  </a>
                              </div>
      
                              <nav className="footer__nav">
                                  <ul className="footer__nav-list">
                                      <li className="footer__nav-item">
                                          <Link className="link link--no-underline" to="/">Home</Link>
                                      </li>
                                      <li className="footer__nav-item">
                                          <Link className="link link--no-underline" to="/what-we-do">What we do</Link>
                                      </li>
                                      <li className="footer__nav-item">
                                          <Link className="link link--no-underline" to="/contact">Contact us</Link>
                                      </li>
                                  </ul>
                              </nav>
      
                              <small className="footer__legal">{`© ${new Date().getFullYear()} ${config.manifestName}`}</small>
                          </div>
                      </footer>
                  </div>
              </div>
          </div>
      </div>
        );
}
}