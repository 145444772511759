module.exports = {
  siteTitle: 'Lindan Classic Moments', // <title>
  manifestName: 'Lindan Classic Moments',
  manifestShortName: 'Lindan', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/ms-icon-310x310.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  socialLinks: [
    {
      icon: 'fab fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/rooneychase',
    },
    {
      icon: 'fab fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/rooneychase',
    },
    {
      icon: 'fa fa-phone',
      name: 'Call',
      url: 'tel:07958640393',
    },
    {
      icon: 'fa fa-envelope',
      name: 'Email',
      url: 'mailto:enquiries@rooneychase.com',
    },
    
  ],
};
